import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SubNavbar from "../../components/SubNavbar";
import { getUserOrders } from "../../redux/orders/orderSlice";
import HistoryPageTable from "./components";

import "./index.scss";
function HistoryPage() {
  const orders = useSelector((state) => state.orders.orders);
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user?.foundUser
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserOrders(user.id));
  }, []);
  return (
    <div className="orders-page-root-container-parent-parent">
      <SubNavbar></SubNavbar>
      <div
        style={{ height: window.innerWidth <= 667 ? "70vh" : "90vh" }}
        className="orders-page-root-container-parent"
      >
        <div
          style={{
            height: window.innerWidth <= 667 && "55vh",
            width: window.innerWidth <= 667 && "85%",
          }}
          className="orders-page-root-container"
        >
          <div className="orders-page-container-header">
            <h3>TRADING HISTORY</h3>
          </div>
          {!orders ? (
            "No trade history"
          ) : (
            <div>
              <HistoryPageTable />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryPage;
