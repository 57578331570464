import React, { useState } from "react";
import "./index.scss";
import SubNavbar from "../../components/SubNavbar";
import { CreditCardOutlined } from "@material-ui/icons";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { userServices } from "../../services/userServices";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/users/userSlice";

function PersonalDetailsPage() {
  const [isAddCreditCardClicked, setIsAddCreditCardClicked] = useState(false);
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user?.foundUser
  );

  const dispatch = useDispatch();
  const [firstName, setFirstName] = React.useState(user?.firstName);
  const [lastName, setLastName] = React.useState(user?.lastName);
  const [email, setEmail] = React.useState(user?.email);
  const [phone, setPhone] = React.useState(user?.phone);
  const [job, setJob] = React.useState(user?.job);
  const [gender, setGender] = React.useState(user?.gender);
  const [country, setCountry] = React.useState(user?.country);
  const [city, setCity] = React.useState(user?.city);
  const [streetAddress, setStreetAddress] = React.useState(user?.streetAddress);
  const [postCode, setPostCode] = React.useState(user?.postCode);

  const addCreditCardHandler = () => {
    setIsAddCreditCardClicked(!isAddCreditCardClicked);
  };

  const handleChange = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case "firstName":
        setFirstName(e.target.value);
        return;
      case "lastName":
        setLastName(e.target.value);
        return;
      case "email":
        setEmail(e.target.value);
        return;
      case "phone":
        setPhone(e.target.value);
        return;
      case "job":
        setJob(e.target.value);
        return;
      case "gender":
        setGender(e.target.value);
        return;
      case "country":
        setCountry(e.target.value);
        return;
      case "city":
        setCity(e.target.value);
        return;
      case "streeAddress":
        setStreetAddress(e.target.value);
        return;
      case "postCode":
        setPostCode(e.target.value);
        return;
      default:
        break;
    }
  };
  const handleUpdateUser = (e) => {
    e.preventDefault();

    userServices
      .updateUser(user.id, {
        firstName,
        lastName,
        job,
        gender,
        city,
        country,
        email,
        phone,
        postCode,
        streetAddress,
      })
      .then(() => {
        dispatch(getUser(user?.id));
      });
  };
  return (
    <div className="personal-details-page-root-container-parent-root">
      <SubNavbar></SubNavbar>
      <div className="personal-details-page-root-container-parent">
        <div className="personal-details-page-root-container">
          <div className="personal-details-page-container-header">
            <h3>PERSONAL DETAILS</h3>
          </div>
          <div className="personal-details-page-information-wrapper">
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="firstName"
                placeholder="First Name"
                disabled={true}
                onChange={(e) => handleChange(e)}
                value={firstName}
              />
            </FormControl>
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="lastName"
                disabled={true}
                placeholder="Last Name"
                onChange={(e) => handleChange(e)}
                value={lastName}
              />
            </FormControl>
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="email"
                disabled={true}
                placeholder="Email"
                onChange={(e) => handleChange(e)}
                value={email}
              />
            </FormControl>
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="phone">Phone</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="phone"
                disabled={true}
                placeholder="Phone"
                onChange={(e) => handleChange(e)}
                value={phone}
              />
            </FormControl>
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="jobTitle">Job Title</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="job"
                placeholder="Job Title"
                onChange={(e) => handleChange(e)}
                value={job}
                disabled={true}
              />
            </FormControl>
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="gender">Gender</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="gender"
                placeholder="Gender"
                onChange={(e) => handleChange(e)}
                disabled={true}
                value={gender}
              />
            </FormControl>
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="country">Country</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="country"
                placeholder="Country"
                onChange={(e) => handleChange(e)}
                value={country}
                disabled={true}
              />
            </FormControl>

            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="city">City</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="city"
                placeholder="City"
                onChange={(e) => handleChange(e)}
                disabled={true}
                value={city}
              />
            </FormControl>

            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="streetAdress">Street Address</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="streetAddress"
                placeholder="Street Address"
                onChange={(e) => handleChange(e)}
                disabled={true}
                value={streetAddress}
              />
            </FormControl>
            <FormControl
              focused={true}
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
            >
              <InputLabel htmlFor="postCode">Post Code/Zip</InputLabel>
              <Input
                inputProps={{
                  style: {
                    backgroundColor: "#07232E",
                  },
                }}
                className="input-root"
                name="postCode"
                placeholder="Post Code/Zip"
                onChange={(e) => handleChange(e)}
                value={postCode}
                disabled={true}
              />
            </FormControl>

            <Button
              variant="contained"
              color="success"
              disabled={true}
              style={{ width: "30%", margin: "0 auto" }}
              className="personal-details-page-payment-method-button"
              onClick={(e) => handleUpdateUser(e)}
            >
              Save
            </Button>
            <p className="warning-message">
              You should contact your financial advisor before editing this
              sensitive information!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetailsPage;
