import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getCryptoSymbolData from "../../../../redux/cryptoMarketData/action";
import DropdownItem from "../CollapseBox";
import { StarBorder } from "@material-ui/icons";
import useWebSocket from "react-use-websocket";
import DogeCoinWebsocket from "./dogeCoingWebSocket";
import "./index.scss";
import BtcWebSocket from "./btcWebSocket";
import EthWebSocket from "./ethWebSocket";
import BnbWebSocket from "./bnbWebSocket";
import NeoWebSocket from "./neoWebSocket";
import LtcWebSocket from "./ltcWebSocket";
import AdaWebSocket from "./adaWebSocket";
import XrpWebSocket from "./xrpWebSocket";
import EosWebSocket from "./eosWebSocket";
import IotaWebSocket from "./iotaWebSocket";
import XlmWebSocket from "./xlmWebSocket";
import TrxWebSocket from "./trxWebSocket";
import DashWebSocket from "./dashWebSocket";
import MaticWebSocket from "./maticWebSocket";
import SolWebSocket from "./solWebSocket";
import { userServices } from "../../../../services/userServices";
import { getUser } from "../../../../redux/users/userSlice";
import CeloWebSocket from "./celoWebSocket";
import ShibaWebSocket from "./shibaWebSocket";
import PolkaDotWebSocket from "./polkaDotWebSocket";
import TonWebSocket from "./tonWebSocket";
import AptWebSocket from "./aptWebSocket";
import ReturnPriceComponent from "./returnPriceComponent";

function CryptocurrenciesWebSocket() {
  const [activeSection, setActiveSection] = useState("crypto");

  const socketUrl = "wss://stream.binance.com:9443/stream";
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);
  const messageHistory = React.useRef([]);
  messageHistory.current = React.useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );

  const handleClickSendMessageDoge = React.useCallback(
    () =>
      sendJsonMessage({
        method: "SUBSCRIBE",
        params: ["dogebusd@ticker"],
        id: 1,
      }),
    [sendJsonMessage]
  );

  const handleClickSendMessageBtc = React.useCallback(
    () =>
      sendJsonMessage({
        method: "SUBSCRIBE",
        params: ["btcbusd@ticker"],
        id: 1,
      }),
    [sendJsonMessage]
  );

  React.useEffect(() => {
    handleClickSendMessageDoge();
    handleClickSendMessageBtc();
  }, []);

  const dispatch = useDispatch();
  const onClickSymbolChange = React.useCallback(
    (payload) => {
      dispatch(getCryptoSymbolData(payload));
    },

    []
  );

  const symbol = useSelector((state) => state.cryptoMarketDataReducer.symbol);
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user?.foundUser
  );

  const favorites = user?.favourites?.map((a) => a.symbol);

  const updateFavourites = (f) => {
    userServices
      .updateUserFavourites({
        userId: user.id,
        symbol: f,
      })
      .then(() => dispatch(getUser(user.id)));
  };

  return (
    <div className="crypto-container">
      <div className="crypto-menu">
        <div className="crypto-buttons">
          {/* <button
            className={
              activeSection === "favourites"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("favourites")}
          >
            Favourites
          </button> */}
          <button
            className={
              activeSection === "crypto"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("crypto")}
          >
            Crypto
          </button>
          <button
            className={
              activeSection === "stocks"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("stocks")}
          >
            Stocks
          </button>
          <button
            className={
              activeSection === "commodities"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("commodities")}
          >
            Commodities
          </button>
          <button
            className={
              activeSection === "forex"
                ? "crypto-button-active"
                : "crypto-button"
            }
            onClick={() => setActiveSection("forex")}
          >
            Forex
          </button>
        </div>
        {/* Favourites */}
        {/* {activeSection === "favourites" ? (
          <>
            {favorites.indexOf("BTCUSDT") >= 0 && (
              <div
                className={
                  symbol === "BTCUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("BTCUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/bitcoin.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">BTC/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <BtcWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("BTCUSDT")} />
                  </div>
                </div>
              </div>
            )}
            {favorites.indexOf("ETHUSDT") >= 0 && (
              <div
                className={
                  symbol === "ETHUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("ETHUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/etherium.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">ETH/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <EthWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("ETHUSDT")} />
                  </div>
                </div>
              </div>
            )}

            {favorites.indexOf("NEOUSDT") >= 0 ? (
              <div
                className={
                  symbol === "NEOUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("NEOUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/neo.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">NEO/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <NeoWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("NEOUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("BNBUSDT") >= 0 ? (
              <div
                className={
                  symbol === "BNBUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("BNBUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/bnb.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">BNB/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <BnbWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("BNBUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("LTCUSDT") >= 0 ? (
              <div
                className={
                  symbol === "LTCUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("LTCUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/ltc.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">LTC/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <LtcWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("LTCUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("ADAUSDT") >= 0 ? (
              <div
                className={
                  symbol === "ADAUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("ADAUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/ada.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">ADA/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <AdaWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("ADAUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("XRPUSDT") >= 0 ? (
              <div
                className={
                  symbol === "XRPUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("XRPUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/xrp.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">XRP/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <XrpWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("XRPUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("EOSUSDT") >= 0 ? (
              <div
                className={
                  symbol === "EOSUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("EOSUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/eos.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">EOS/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <EosWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("EOSUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("IOTAUSDT") >= 0 ? (
              <div
                className={
                  symbol === "IOTAUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("IOTAUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/iota.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">IOTA/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <IotaWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("IOTAUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("XLMUSDT") >= 0 ? (
              <div
                className={
                  symbol === "XLMUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
                onClick={() => onClickSymbolChange("XLMUSDT")}
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/xlm.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">XLM/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <XlmWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("XLMUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}

            {favorites.indexOf("TRXUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("TRXUSDT")}
                className={
                  symbol === "TRXUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/trx.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">TRX/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <TrxWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("TRXUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("DASHUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("DASHUSDT")}
                className={
                  symbol === "DASHUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/dash.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">DASH/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <DashWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("DASHUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("POLUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("POLUSDT")}
                className={
                  symbol === "POLUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/matic.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">MATIC/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <MaticWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("POLUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("DOGEUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("DOGEUSDT")}
                className={
                  symbol === "DOGEUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/doge.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">DOGE/USD</div>
                </div>
                <div className="crypto-price-star-wrap">
                  <DogeCoinWebsocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("DOGEUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("SOLUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("SOLUSDT")}
                className={
                  symbol === "SOLUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/sol.svg" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">SOL/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <SolWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("SOLUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {favorites.indexOf("CELOUSDT") >= 0 ? (
              <div
                onClick={() => onClickSymbolChange("CELOUSDT")}
                className={
                  symbol === "CELOUSDT" ? "crypto-rows-active" : "crypto-rows"
                }
              >
                <div className="crypto-icon-name-wrap">
                  <div>
                    <img src="/celo.png" width={"30px"} alt=""></img>
                  </div>
                  <div className="crypto-name">CELO/USD</div>
                </div>

                <div className="crypto-price-star-wrap">
                  <CeloWebSocket />
                  <div className="star-div">
                    <StarBorder onClick={() => updateFavourites("CELOUSDT")} />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </>
        ) : (
          false
        )} */}

        {/* CRYPTO */}
        {activeSection === "crypto" ? (
          <>
            <div
              className={
                symbol === "BTCUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("BTCUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/bitcoin.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">BTC/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <BtcWebSocket />
                <div className="star-div">
                  <StarBorder
                    className="star-svg"
                    onClick={() => updateFavourites("BTCUSDT")}
                  />
                </div>
              </div>
            </div>

            <div
              className={
                symbol === "ETHUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("ETHUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/etherium.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">ETH/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <EthWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("ETHUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "BNBUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("BNBUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/bnb.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">BNB/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <BnbWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("BNBUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "NEOUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("NEOUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/neo.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">NEO/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <NeoWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("NEOUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "LTCUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("LTCUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/ltc.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">LTC/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <LtcWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("LTCUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "ADAUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("ADAUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/ada.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">ADA/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <AdaWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("ADAUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "XRPUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("XRPUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/xrp.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">XRP/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <XrpWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XRPUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "EOSUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("EOSUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/eos.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">EOS/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <EosWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("EOSUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "IOTAUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("IOTAUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/iota.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">IOTA/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <IotaWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("IOTAUSDT")} />
                </div>
              </div>
            </div>
            <div
              className={
                symbol === "XLMUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
              onClick={() => onClickSymbolChange("XLMUSDT")}
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/xlm.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">XLM/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <XlmWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XLMUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("TRXUSDT")}
              className={
                symbol === "TRXUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/trx.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">TRX/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <TrxWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("TRXUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("DASHUSDT")}
              className={
                symbol === "DASHUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/dash.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">DASH/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <DashWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("DASHUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("POLUSDT")}
              className={
                symbol === "POLUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/matic.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">POL/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <MaticWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("POLUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("DOGEUSDT")}
              className={
                symbol === "DOGEUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/doge.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">DOGE/USD</div>
              </div>
              <div className="crypto-price-star-wrap">
                <DogeCoinWebsocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("DOGEUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("SOLUSDT")}
              className={
                symbol === "SOLUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/sol.svg" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">SOL/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <SolWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("SOLUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("CELOUSDT")}
              className={
                symbol === "CELOUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/celo.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">CELO/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <CeloWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("CELOUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("SHIBUSDT")}
              className={
                symbol === "SHIBUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/shib.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">SHIB/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <ShibaWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("SHIBUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("DOTUSDT")}
              className={
                symbol === "DOTUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/polka.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">DOT/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <PolkaDotWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("DOTUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("TONUSDT")}
              className={
                symbol === "TONUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/ton.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">TON/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <TonWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("TONUSDT")} />
                </div>
              </div>
            </div>
            <div
              onClick={() => onClickSymbolChange("APTUSDT")}
              className={
                symbol === "APTUSDT" ? "crypto-rows-active" : "crypto-rows"
              }
            >
              <div className="crypto-icon-name-wrap">
                <div>
                  <img src="/apt.png" width={"30px"} alt=""></img>
                </div>
                <div className="crypto-name">APT/USD</div>
              </div>

              <div className="crypto-price-star-wrap">
                <AptWebSocket />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("APTUSDT")} />
                </div>
              </div>
            </div>
          </>
        ) : (
          false
        )}

        {/* STOCKS */}
        {activeSection === "stocks" ? (
          <>
            <div
              className={
                symbol === "GOOGLUSD" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("GOOG")}
            >
              <div>
                <img src="/alphabet-inc.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Alphabet Inc.</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"GOOG"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("GOOG")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={symbol === "GS" ? "stocks-rows-active" : "stocks-rows"}
              onClick={() => onClickSymbolChange("GS")}
            >
              <div>
                <img src="/sachs.svg" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Goldman Sachs</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"GS"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("GSUSD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "AMZN" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("AMZN")}
            >
              <div>
                <img src="/Amazon_icon.svg" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Amazon Inc.</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"AMZN"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("AMZN")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "BABA" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("BABA")}
            >
              <div>
                <img src="/alibaba.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Alibaba Group</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"BABA"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("BABA")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "TSLA" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("TSLA")}
            >
              <div>
                <img src="/tesla.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Tesla Inc.</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"TSLA"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("TSLA")} />
                </div>
              </div>{" "}
            </div>
            {/* <div
              className={symbol === "FB" ? "stocks-rows-active" : "stocks-rows"}
              onClick={() => onClickSymbolChange("FB")}
            >
              <div>
                <img src="/facebook.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Facebook Inc.</div>
              <div className="crypto-price-star-wrap">
                <FacebookPrice />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("FB")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={symbol === "BA" ? "stocks-rows-active" : "stocks-rows"}
              onClick={() => onClickSymbolChange("BA")}
            >
              <div>
                <img src="/boeing.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Boeing Co</div>
              <div className="crypto-price-star-wrap">
                <FacebookPrice />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("BA")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "IBM" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("IBM")}
            >
              <div>
                <img src="/ibm.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">IBM</div>
              <div className="crypto-price-star-wrap">
                <FacebookPrice />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("IBM")} />
                </div>
              </div>{" "}
            </div> */}
            <div
              className={
                symbol === "MSFT" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("MSFT")}
            >
              <div>
                <img src="/microsoft-icon.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Microsoft Corp.</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"MSFT"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("MSFT")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "CAT" ? "stocks-rows-active" : "stocks-rows"
              }
              onClick={() => onClickSymbolChange("CAT")}
            >
              <div>
                <img src="/cat.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Caterpilar Inc.</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"CAT"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("CAT")} />
                </div>
              </div>{" "}
            </div>
            <div
              onClick={() => onClickSymbolChange("LMT")}
              className={
                symbol === "LMT" ? "stocks-rows-active" : "stocks-rows"
              }
            >
              <div>
                <img src="/lockhgeadMartin.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Lockheed Martin</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"LMT"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("LMT")} />
                </div>
              </div>{" "}
            </div>
            <div
              onClick={() => onClickSymbolChange("JEPI")}
              className={
                symbol === "JEPI" ? "stocks-rows-active" : "stocks-rows"
              }
            >
              <div>
                <img src="/morgan.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">JPMorgan Chase</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"JEPI"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("JEPI")} />
                </div>
              </div>{" "}
            </div>
            <div
              onClick={() => onClickSymbolChange("WFC")}
              className={
                symbol === "WFC" ? "stocks-rows-active" : "stocks-rows"
              }
            >
              <div>
                <img src="/wells.png" width={"30px"} alt=""></img>
              </div>
              <div className="stocks-name">Wells Fargo</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"WFC"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("WFC")} />
                </div>
              </div>{" "}
            </div>
          </>
        ) : (
          false
        )}
        {/* FOREX */}
        {activeSection === "forex" ? (
          <>
            <div
              className={
                symbol === "EURUSD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("EURUSD")}
            >
              <div className="forex-name">EUR/USD</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"EUR-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("GBP/USD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "GBPUSD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("GBPUSD")}
            >
              <div className="forex-name">GBP/USD</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"GBP-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("GBP/USD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "GBPCAD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("GBPCAD")}
            >
              <div className="forex-name">GBP/CAD</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"GBP-CAD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("GBP/CAD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "AUDCAD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("AUDCAD")}
            >
              <div className="forex-name">AUD/CAD</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"AUD-CAD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("AUD/CAD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "AUDJPY" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("AUDJPY")}
            >
              <div className="forex-name">AUD/JPY</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"AUD-JPY"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("AUD/JPY")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "NZDCHF" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("NZDCHF")}
            >
              <div className="forex-name">NZD/CHF</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"NZD-CHF"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("NZD/CHF")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "USDJPY" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("USDJPY")}
            >
              <div className="forex-name">USD/JPY</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"USD-JPY"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("USD/JPY")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "AUDUSD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("AUDUSD")}
            >
              <div className="forex-name">AUD/USD</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"AUD-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("AUD/USD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "GBPNZD" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("GBPNZD")}
            >
              <div className="forex-name">GBP/NZD</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"GBP-NZD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("GBP/NZD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "EURCHF" ? "forex-rows-active" : "forex-rows"
              }
              onClick={() => onClickSymbolChange("EURCHF")}
            >
              <div className="forex-name">EUR/CHF</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"EUR-CHF"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("EUR/CHF")} />
                </div>
              </div>{" "}
            </div>
            <div
              onClick={() => onClickSymbolChange("EURJPY")}
              className={
                symbol === "EURJPY" ? "forex-rows-active" : "forex-rows"
              }
            >
              <div className="forex-name">EUR/JPY</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"EUR-JPY"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("EUR/JPY")} />
                </div>
              </div>{" "}
            </div>
            <div
              onClick={() => onClickSymbolChange("GBPJPY")}
              className={
                symbol === "GBPJPY" ? "forex-rows-active" : "forex-rows"
              }
            >
              <div className="forex-name">GBP/JPY</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"GBP-JPY"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("GBP/JPY")} />
                </div>
              </div>{" "}
            </div>
            <div
              onClick={() => onClickSymbolChange("USDCHF")}
              className={
                symbol === "USDCHF" ? "forex-rows-active" : "forex-rows"
              }
            >
              <div className="forex-name">USD/CHF</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"USD-CHF"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("USD/CHF")} />
                </div>
              </div>{" "}
            </div>
          </>
        ) : (
          false
        )}

        {/* Commodities */}
        {activeSection === "commodities" ? (
          <>
            <div
              className={
                symbol === "XAUUSD"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("XAUUSD")}
            >
              <div>
                <img src="/goldSpot.webp" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Gold</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"XAU-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XAUUSD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "XAGUSD"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("XAGUSD")}
            >
              <div>
                <img src="/silverSpot.webp" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Silver</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"XAG-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XAGUSD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "USOIL"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("USOIL")}
            >
              <div>
                <img src="/crudeOil.png" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Crude Oil (WTI)</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"WTI-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("USOIL")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "NATURALGAS"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("NATURALGAS")}
            >
              <div>
                <img src="/naturalGas.png" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Natural Gas</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"NG-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("NATURALGAS")} />
                </div>
              </div>{" "}
            </div>
            {/* <div
              className={
                symbol === "XG/USD"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("XG/USD")}
            >
              <div>
                <img src="/copperAgr.png" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Copper Agr.</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"XG-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XG/USD")} />
                </div>
              </div>{" "}
            </div> */}
            <div
              className={
                symbol === "PLATINUM"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("PLATINUM")}
            >
              <div>
                <img src="/platinum.png" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Platinum</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"XPT-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("PLATINUM")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "XPDUSD"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("XPDUSD")}
            >
              <div>
                <img src="/palladium.webp" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Palladium</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"XPD-USD"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("XPDUSD")} />
                </div>
              </div>{" "}
            </div>
            <div
              className={
                symbol === "CORN"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("CORN")}
            >
              <div>
                <img src="/corn.png" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Corn</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"C_1"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("CORN")} />
                </div>
              </div>{" "}
            </div>
            {/* <div
              className={
                symbol === "KC1"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("KC1")}
            >
              <div>
                <img
                  src="/coffee.png"
                  width={"30px"}
                  alt=""
                  height={"20px"}
                ></img>
              </div>
              <div className="commodities-name">Coffee</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"KC1"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("KC1")} />
                </div>
              </div>{" "}
            </div> */}
            {/* <div
              className={
                symbol === "UXA"
                  ? "commodities-rows-active"
                  : "commodities-rows"
              }
              onClick={() => onClickSymbolChange("UXA")}
            >
              <div>
                <img src="/uranium.png" width={"30px"} alt=""></img>
              </div>
              <div className="commodities-name">Uranium</div>
              <div className="crypto-price-star-wrap">
                <ReturnPriceComponent symbol={"UXA"} />
                <div className="star-div">
                  <StarBorder onClick={() => updateFavourites("UXA")} />
                </div>
              </div>{" "}
            </div> */}
          </>
        ) : (
          false
        )}
      </div>
    </div>
  );
}

export default CryptocurrenciesWebSocket;
